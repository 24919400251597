










import Vue from 'vue'
import hamburgerButtonImage from '@/assets/hamburger-button.svg'
import backButtonImage from '@/assets/back-button.svg'

export default Vue.extend({
  name: 'Toolbar',
  props: {
    title: String,
    iconType: {
      validator: function (value: string) {
        return ['none', 'menu', 'back'].indexOf(value) !== -1
      }
    }
  },
  methods: {
    onClickLeftIcon (e: Event) {
      this.$emit('onClickLeftIcon', e)
    }
  },
  computed: {
    leftIconSrc () {
      if (this.iconType === 'menu') {
        return hamburgerButtonImage
      } else if (this.iconType === 'back') {
        return backButtonImage
      }
      return null
    }
  }
})
