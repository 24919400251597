/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-use-before-define */

import Receipt, { ReceiptItem } from '@/models/Receipt'

const defaultHost = '/1'

const post = async (path: string, formData: URLSearchParams, host = defaultHost) => {
  const headers: any = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
  if (window.localStorage.access_token) {
    headers.Authorization = `Bearer ${window.localStorage.access_token}`
  }
  const obj = await fetch(host + path, {
    body: formData,
    method: 'POST',
    headers: headers
  })
  return obj
}

const get = async (path: string, formData: URLSearchParams = new URLSearchParams(), host = defaultHost) => {
  const headers: any = {}
  if (window.localStorage.access_token) {
    headers.Authorization = `Bearer ${window.localStorage.access_token}`
  }
  const queries: string[] = []
  formData.forEach((v, k) => {
    queries.push(`${k}=${v}`)
  })

  const obj = await fetch(host + path + `?${queries.join('&')}`, {
    method: 'GET',
    headers: headers
  })
  return obj
}

export const login = async (account: string, password: string) => {
  const formData = new URLSearchParams()
  formData.append('action', 'login')
  formData.append('grant_type', 'email-password')
  formData.append('email', account)
  formData.append('password', password)

  const obj = await post('/token', formData)
    .then(res => res.json())

  if (obj.error) {
    throw new Error(obj.error_description)
  }
  return obj.access_token
}

export const logout = async () => {
  const formData = new URLSearchParams()
  formData.append('action', 'revoke-token')

  const obj = await post('/token', formData)
    .then(res => res.json())

  if (obj.error) {
    throw new Error(obj.error_description)
  }
  return obj
}

export const register = async (email: string, username: string, password: string, fname: string, lname: string, gender: string, birthday: string, zip: string, phone: string) => {
  const formData = new URLSearchParams()
  formData.append('action', 'register')
  formData.append('email', email)
  formData.append('username', username)
  formData.append('password', password)
  formData.append('first_name', fname)
  formData.append('last_name', lname)
  formData.append('gender', gender)
  formData.append('birthday', birthday)
  formData.append('zip', zip)
  formData.append('phone', phone)

  const obj = await post('/register', formData)
    .then(res => res.json())
  if (obj.error) {
    throw new Error(obj.error_description)
  }
  const data = obj.data
  return data
}

export const getUserInfo = async () => {
  return {
    email: 'demo@demo.com',
    username: 'demo',
    first_name: 'aa',
    last_name: 'bb',
    gender: 'other',
    birthday: '2000-01-01',
    zip: 'x',
    phone: '+1 123456789'
  }
  // const obj = await get('/user-profile')
  //   .then(res => res.json())

  // if (obj.error) {
  //   throw new Error(obj.error_description)
  // }
  // return {
  //   email: obj.email,
  //   username: obj.username,
  //   first_name: obj.first_name,
  //   last_name: obj.last_name,
  //   gender: obj.gender,
  //   birthday: obj.birthday,
  //   zip: obj.zipcode,
  //   phone: obj.mobile_phone
  // }
}

export const updateUserInfo = async (email: string, username: string, fname: string, lname: string, gender: string, birthday: string, zip: string, phone: string) => {
  return {}
  // const formData = new URLSearchParams()
  // formData.append('action', '???')
  // formData.append('email', email)
  // formData.append('username', username)
  // formData.append('first_name', fname)
  // formData.append('last_name', lname)
  // formData.append('gender', gender)
  // formData.append('birthday', birthday)
  // formData.append('zip', zip)
  // formData.append('phone', phone)

  // const obj = await post('/???', formData)
  //   .then(res => res.json())
  // if (obj.error) {
  //   throw new Error(obj.error_description)
  // }
  // const data = obj.data
  // return data
}

export const updatePassword = async (oldPassword: string, newPassword: string, newPassword2: string) => {
  if (newPassword !== newPassword2) {
    throw Error('New password and confirm password don\'t match.')
  }
  return {}
  // const formData = new URLSearchParams()
  // formData.append('action', 'change-password')
  // formData.append('old_password', oldPassword)
  // formData.append('new_password', newPassword)

  // const obj = await post('/password', formData)
  //   .then(res => res.json())
  // if (obj.error) {
  //   throw new Error(obj.error_description)
  // }
  // const data = obj.data
  // return data
}

/**
 * 忘記密碼場合時使用
 * @param token 來自於email連結等
 */
export const resetPassword = async (token: string, newPassword: string, newPassword2: string) => {
  if (newPassword !== newPassword2) {
    throw Error('New password and confirm password don\'t match.')
  }
  return {}
}

export const sendResetPasswordEmail = async (email: string) => {
  return {}
}

export const getReceiptList = async (year = -1, month = -1): Promise<Receipt[]> => {
  // const list = Array.from({ length: 500 }, (v, k) => new Receipt(`${k}`, new Date(Date.now() - 24 * 60 * 60 * 1000 * k), 10 + k / 100, 'Demo', 'TW'))
  // return list.filter(it => {
  //   const t = year * 12 + month
  //   const t2 = it.date.getFullYear() * 12 + it.date.getMonth() + 1
  //   return t === t2
  // })
  const formData = new URLSearchParams()
  if (year > 0 && month > 0) {
    formData.append('not_before', new Date(year, month - 1, 1).toISOString())
    formData.append('not_after', new Date(year, month, 1).toISOString())
  } else if (year > 0) {
    formData.append('not_before', new Date(year, 0, 1).toISOString())
    formData.append('not_after', new Date(year + 1, 0, 1).toISOString())
  }
  const obj = await get('/receipts', formData)
    .then(res => res.json())

  if (obj.error) {
    throw new Error(obj.error_description)
  }
  return obj.data.items.map((it: any) => {
    const receipt = new Receipt(it.receipt_id, it.date, it.amount, it.store_name, it.store_address)
    return receipt
  })
}

export const getReceipt = async (id: string): Promise<Receipt> => {
  const obj = await get(`/receipts/${id}`)
    .then(res => res.json())

  if (obj.error) {
    throw new Error(obj.error_description)
  }
  const it = obj.data
  const receipt = new Receipt(it.receipt_id, it.date, it.amount, it.store_name, it.store_address)
  receipt.setItems(
    it.items.map((ele: any) => new ReceiptItem(ele.description, ele.quantity, ele.amount))
  )
  return receipt
}

export default {
  login,
  logout,
  register,
  getUserInfo,
  updateUserInfo,
  updatePassword,
  resetPassword,
  sendResetPasswordEmail,
  getReceiptList,
  getReceipt
}
