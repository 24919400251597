<template>
  <div class="main-view">
    <div class="hello_message">
      Hi ,{{username}}
    </div>
    <Toolbar
      :title="$route.name"
      iconType="menu"
      @onClickLeftIcon="onClickLeftIcon"/>
    <transition name="slide">
      <ul class="menu" v-show="isMenuExpaned">
        <li>
          <router-link
          v-on:click.native="isMenuExpaned = false"
          to="/dashboard">Main Page</router-link>
        </li>
        <li>
          <router-link
          v-on:click.native="isMenuExpaned = false"
          to="/user-profiles">Profile</router-link>
        </li>
        <li>
          <router-link
          v-on:click.native="logout"
          to="/login">Logout</router-link>
        </li>
      </ul>
    </transition>
    <router-view/>
  </div>
</template>

<script>

import Vue from 'vue'
import Toolbar from '@/components/Toolbar.vue'
import API from '@/utils/API'

export default Vue.extend({
  name: 'Login',
  components: {
    Toolbar
  },
  data: function () {
    return {
      username: '',
      isMenuExpaned: false
    }
  },
  methods: {
    onClickLeftIcon () {
      this.isMenuExpaned = !this.isMenuExpaned
    },
    toggle (e) {
      e.target.parentElement.querySelector('.nested').classList.toggle('active')
    },
    alertTodo (text) {
      alert(`TODO: ${text}`)
    },
    logout () {
      API.logout()
      this.isMenuExpaned = false
      window.localStorage.removeItem('access_token')
      window.localStorage.removeItem('username')
    }
  },
  mounted () {
    API.getUserInfo().then(it => {
      this.username = it.username
      window.localStorage.setItem('username', it.username)
    })
  }
})
</script>

<style scoped lang="scss">
  .main-view {
    width: 100vw;
    min-height: calc(100vh - 54px);
    padding-top: 54px;
  }
  .menu {
    width: 300px;
    height: calc(100% - 64px);
    overflow-y: scroll;
    background: #f8f8f4;
    position: absolute;
    user-select: none;
    z-index: 10;
    a, span {
      height: 48px;
      display: flex;
      align-items: center;
      font-size: 18px;
      letter-spacing: 1.13px;
      color: #215b36;
      text-decoration: none;
      cursor: pointer;
    }
  }
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    a, span {
      padding-left: 40px;
    }
    > li > ul {
      background-color: #f8f8f4;
      a, span {
        padding-left: 54px;
      }
    }
  }
  .slide-enter-active {
    transition: transform .2s ease-in-out;
  }
  .slide-leave-active {
    transition: transform .2s ease-in-out;
  }
  .slide-enter, .slide-leave-to {
    transform: translateX(-300px);
  }
  .slide-enter-to, .slide-leave {
    transform: translateX(0);
  }
  .nested {
    display: block;
    overflow: hidden;
    max-height: 0px;
    transition: max-height .2s ease-in-out;
  }
  .active {
    max-height: 144px;
    transition: max-height .1s ease-in-out;
  }
  .hello_message {
    position: absolute;
    top: 54px;
    right: 26px;
    color: white;
    z-index: 1;
    height: 64px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
  }
</style>
