import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Main from '../views/Main.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'User Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
    meta: { requiresToken: true },
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'E myreceipt',
        component: () => import('@/views/ReceiptList.vue')
      },
      {
        path: '/user-profiles',
        name: 'Profile',
        component: () => import('@/views/Profile.vue')
      }
    ]
  },
  {
    path: '/receipts/:id?',
    name: 'Receipt Detail',
    component: () => import('@/views/ReceiptInformation.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Registeration.vue'),
    beforeEnter: (to, from, next) => {
      console.log(to)
      console.log(from)
      if (from.matched.some(record => {
        return record.path === '/register-success'
      })) {
        next({ path: '/login' })
      } else {
        next()
      }
    }
  },
  {
    path: '/register-success',
    name: 'Register Success',
    component: () => import('@/views/RegisterSuccess.vue')
  },
  {
    path: '/change-password',
    name: 'Change Password',
    component: () => import('@/views/NewPassword.vue')
  },
  {
    path: '/forget-password',
    name: 'Forget Password',
    component: () => import('@/views/ForgetPassword.vue')
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import('@/views/ResetPassword.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})
router.beforeEach((to, form, next) => {
  if (to.matched.some(record => {
    return record.meta.requiresToken
  })) {
    if (window.localStorage.getItem('access_token') === null) {
      next({ path: '/login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
