<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  name: 'Root'
})
</script>

<style lang="scss">

html, body {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

#app {
  font-family: PingFangTC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f4;
  padding-bottom: 1px;
}

.error-message {
  color: rgb(224, 32, 32);
  font-size: 16px;
  font-weight: 500;
  min-height: 24px;
}
</style>
