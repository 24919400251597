export default class Receipt {
  id: string
  date: string
  storeName: string
  storeAddress: string
  amount: string
  items: ReceiptItem[] = []
  constructor (id: string, date: string, amount: string, storeName: string, storeAddress: string) {
    this.id = id
    this.date = date
    this.amount = amount
    this.storeName = storeName
    this.storeAddress = storeAddress
  }

  setItems (items: ReceiptItem[]) {
    this.items = items
  }
}

export class ReceiptItem {
  description: string
  quantity: string
  amount: string
  constructor (description: string, quantity: string, amount: string) {
    this.description = description
    this.quantity = quantity
    this.amount = amount
  }
}
